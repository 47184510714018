@import '~src/styles/_mixins.scss';

.header-outer-container {
  @include outer-container;
  position: fixed;
  z-index: 1;
  background-color: #ffffff;
}

.header-inner-container {
  @include inner-container;
  padding-bottom: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 960px) {
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }
  .brand {
    font-weight: 900;
    font-size: 28px;
    letter-spacing: 1px;
    span {
      color: #bdbdbd;
    }
  }
  .burger {
    width: 35px;
  }
}
