@import '~src/styles/_mixins.scss';

.ability-outer-container {
  @include outer-container;
  margin-bottom: 70px;
}
.ability-inner-container {
  @include inner-container;
  h2 {
    letter-spacing: 0.5px;
    position: relative;
    font-weight: 800;
    &::after {
      content: '';
      width: 118px;
      border: 4px solid purple;
      display: block;
      position: absolute;
      bottom: 3px;
      opacity: 0.5;
      left: 10px;
    }
  }
  .progress {
    height: 6px;
    margin-bottom: 12px;
    border-radius: 0.5rem;
  }
  .progress-bar {
    font-size: 16px;
    text-align: right;
    background-color: #f5c000;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
  }
  label {
    letter-spacing: 0.5px;
    margin-bottom: 1px;
    font-size: 17px;
  }
}
