@import '~src/styles/_mixins.scss';

.history-outer-container {
  @include outer-container;
  margin-bottom: 70px;
}
.history-inner-container {
  @include inner-container;
  font-size: 18px;
  h2 {
    letter-spacing: 0.5px;
    position: relative;
    font-weight: 800;
    margin-bottom: 26px;
    &::after {
      content: '';
      width: 106px;
      border: 4px solid green;
      display: block;
      position: absolute;
      bottom: 3px;
      opacity: 0.5;
      left: 10px;
    }
  }
  .list-container {
    display: grid;
    grid-gap: 24px 26px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 850px) {
      grid-template-columns: 1fr;
    }
  }
  .year {
    font-weight: 600;
    position: relative;
    &::after {
      content: '';
      border: 1px solid #c7c7c7;
      width: 70%;
      display: block;
      position: absolute;
      opacity: 0.5;
      bottom: 11px;
      z-index: -1;
      right: 8%;
    }
  }
  .position {
    font-weight: 700;
    color: #ff6d6d;
    font-size: 18px;
    img {
      margin-left: 3px;
      margin-bottom: 3px;
      width: 16px;
    }
  }
  .desc {
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 2px;
  }
  .unlock {
    margin-bottom: 3px;
    img {
      width: 18px;
      margin-bottom: 5px;
    }
  }
  .tag-list {
    margin-top: 12px;
  }
  .tag {
    padding: 0px 8px 4px;
    background-color: #7b91ff;
    color: white;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 2px;
    border-radius: 8px;
    font-size: 15px;
  }
}
