@import '~src/styles/_mixins.scss';

.brief-outer-container {
  @include outer-container;
  height: 620px;
  justify-content: center;
}
.brief-inner-container {
  @include inner-container;
  display: flex;
  align-items: center;
  z-index: 0;
  .layer-top {
    position: relative;
    z-index: 2;
  }
  .profile-wrapper {
    img {
      max-width: 350px;
      width: calc(100vw - 450px);
    }
  }
  .info-wrapper {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 44px;
    position: relative;
    .greeting {
      font-weight: 900;
      font-size: 28px;
    }
    .occupations {
      font-weight: 600;
      color: #9e9e9e;
    }
    .desc {
      font-size: 20px;
      margin-top: 30px;
    }
    .resume {
      font-weight: 900;
      position: absolute;
      top: 196px;
      color: #6b6b6b;
      &::after {
        content: '';
        border: 2px solid #d8d8d8;
        width: 100px;
        display: block;
        top: -1px;
        position: relative;
        left: 17px;
      }
      &:hover {
        color: #000000;
      }
      span {
        margin-left: 3px;
        font-size: 14px;
        text-decoration: underline;
      }
    }
    .social {
      position: absolute;
      top: 196px;
      left: 150px;
      img {
        width: 30px;
        margin-right: 15px;
      }
    }
  }
  .square-y {
    width: 400px;
    height: 209px;
    position: absolute;
    border: 8px solid yellow;
    right: 105px;
    top: -30px;
  }
  .square-g {
    width: 130px;
    height: 168px;
    position: absolute;
    border: 8px solid rgb(85, 255, 106);
    right: 60px;
    top: 70px;
  }
}
@media (max-width: 860px) {
  .brief-inner-container {
    .square-y,
    .square-g {
      display: none;
    }
  }
}
@media (max-width: 699px) {
  .brief-inner-container {
    justify-content: center;
    .profile-wrapper {
      position: absolute;
      img {
        opacity: 0.1;
        width: auto;
      }
    }
    .info-wrapper {
      max-width: 422px;
    }
  }
}
