.icon-list {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.icon-author {
  a {
    text-decoration: underline;
    color: blue;
  }
}
